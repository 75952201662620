<template>
  <CModal
    :show="true"
    :no-close-on-backdrop="true"
    :centered="true"
    size="lg"
    color="info"
  >
    <Form
      :data="defaultData"
      :errors="errors"
      @itemChange="onItemChange"
    />
    <template #header>
      <h3 class="modal-title">Create New {{ $options.entitySingle }}</h3>
      <CButtonClose @click="onCancel" class="text-white"/>
    </template>
    <template #footer>
      <form-create-button
        :showLoading="isSubmitting"
        @insertNew="onInsertNew"
        @insertClose="onInsertClose"
        @cancel="onCancel"
      />
    </template>
  </CModal>
</template>

<script>
import startCase from 'lodash/startCase'
import { mapActions, mapState } from 'vuex'
import Form from './Form'

import { entity, entitySingle } from '../entity'

export default {
  name: 'Create',
  components: {
    Form
  },
  entitySingle: startCase(entitySingle),
  data () {
    return {
      defaultData: { is_active: true },
      insertData: {}
    }
  },
  computed: {
    ...mapState({
      isSubmitting: state => state[entity].new.submitting,
      errors: state => state[entity].new.errors
    })
  },
  methods: {
    ...mapActions({
      insertItem: `${entity}/insertItem`
    }),
    onItemChange (item) {
      this.insertData = item
    },
    async onInsertNew () {
      const { insertData } = this
      if (insertData) {
        await this.insertItem({ data: insertData, closeSuccess: false })
      }
    },
    async onInsertClose () {
      const { insertData } = this
      if (insertData) {
        await this.insertItem({ data: insertData, closeSuccess: true })
      }
    },
    onCancel () {
      this.$store.commit(`${entity}/ADD_NEW`, false)
    }
  },
  mounted () {
    document.querySelector('body').classList.add('overflow-hidden')
  },
  destroyed () {
    document.querySelector('body').classList.remove('overflow-hidden')
  }
}
</script>
