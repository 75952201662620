import generateKey from '@/utils/generateKey'
export default {
  entity: '',
  entitySingle: '',
  lists: [],
  filters: {},
  pageData: {
    currentPage: 1,
    totalPage: 0,
    perPage: 25,
    from: 0,
    to: 0,
    total: 0
  },
  sortData: {
    column: '',
    direction: ''
  },
  detail: {
    data: null,
    key: generateKey(),
    fetching: false
  },
  new: {
    data: {},
    show: null,
    errors: {},
    key: generateKey(),
    submitting: false
  },
  delete: {
    data: null,
    key: generateKey(),
    submitting: false
  },
  edit: {
    data: null,
    errors: {},
    key: generateKey(),
    submitting: false
  },
  isFetching: false
}
