import axios from '@/utils/axios'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'

const rootDispatch = { root: true }

export default {
  async loadLists ({ commit, state, dispatch }) {
    const { entity, pageData: { currentPage, perPage }, sortData: { column, direction }, filters } = state
    commit('IS_FETCHING', true)
    const { type, payload } = await axios({
      url: `${entity}/lists`,
      method: 'POST',
      data: {
        page: currentPage,
        perPage,
        sortColumn: column,
        sortDirection: direction,
        filters
      }
    })
    commit('IS_FETCHING', false)
    if (type === 200) {
      const { data, meta } = payload
      commit('FETCH_LISTS', data)
      const pageDataPayload = {
        currentPage: meta.current_page,
        perPage: parseInt(meta.per_page, 10),
        totalPage: Math.ceil(meta.total / parseInt(meta.per_page, 10)),
        total: meta.total || 0,
        to: meta.to || 0,
        from: meta.from || 0
      }
      commit('UPDATE_PAGE_DATA', pageDataPayload)
      return
    }
    dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
  },
  changePage ({ commit, dispatch }, payload) {
    commit('UPDATE_PAGE_DATA', payload)
    dispatch('loadLists')
  },
  changeSort ({ commit, dispatch }, payload) {
    commit('UPDATE_SORT_DATA', payload)
    dispatch('loadLists')
  },
  async insertItem ({ commit, state, dispatch }, payload) {
    const { data, closeSuccess = true } = payload
    const { entity, entitySingle } = state
    commit('ADD_NEW_SUBMITTING', true)
    commit('ADD_NEW_ERROR', {})
    const { type, payload: returnPayload } = await axios({
      url: `${entity}`,
      method: 'POST',
      data
    })
    commit('ADD_NEW_SUBMITTING', false)
    if (type === 200) {
      if (closeSuccess) {
        commit('ADD_NEW', false)
      } else {
        commit('ADD_NEW', true)
      }
      dispatch('loadLists')
      dispatch('addNotification', { type: 'success', message: `New ${lowerCase(entitySingle)} added successfully!` }, rootDispatch)
      return
    }
    if (type === 422) {
      const { errors } = returnPayload
      const errorFields = Object.keys(errors).reduce((retData, curKey) => {
        retData[curKey] = errors[curKey][0]
        return retData
      }, {})
      commit('ADD_NEW_ERROR', errorFields)
      return
    }
    dispatch('addNotification', { type: 'error', message: returnPayload.message }, rootDispatch)
  },
  async updateItem ({ commit, state, dispatch }, payload) {
    const { entity, entitySingle } = state
    const { id, data, successClose = true } = payload
    commit('EDIT_DATA_SUBMITTING', true)
    const { type, payload: returnPayload } = await axios({
      url: `${entity}/${id}`,
      method: 'PATCH',
      data
    })
    commit('EDIT_DATA_SUBMITTING', false)
    if (type === 200) {
      commit('EDIT_DATA_ERROR', {})
      if (successClose) {
        commit('EDIT_DATA', null)
      } else {
        commit('EDIT_DATA', returnPayload.data)
      }
      dispatch('addNotification', { type: 'success', message: `${startCase(entitySingle)} updated successfully!` }, rootDispatch)
      dispatch('loadLists')
      return
    }
    if (type === 422) {
      const { errors } = returnPayload
      const errorFields = Object.keys(errors).reduce((retData, curKey) => {
        retData[curKey] = errors[curKey][0]
        return retData
      }, {})
      commit('EDIT_DATA_ERROR', errorFields)
      return
    }
    dispatch('addNotification', { type: 'error', message: data }, rootDispatch)
  },
  async deleteItem ({ commit, state, dispatch }, payload) {
    const { entity, entitySingle } = state
    const { id } = payload
    commit('DELETE_DATA_SUBMITTING', true)
    const { type, data } = await axios({
      url: `${entity}/${id}`,
      method: 'DELETE'
    })
    commit('DELETE_DATA_SUBMITTING', false)
    if (type === 200) {
      dispatch('addNotification', { type: 'success', message: `${startCase(entitySingle)} deleted successfully!` }, rootDispatch)
    } else {
      dispatch('addNotification', { type: 'error', message: data }, rootDispatch)
    }
    commit('DELETE_DATA', null)
    dispatch('loadLists')
  }
}
