<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon :name="tableIcon" /> {{ pageTitle }}
          <CButtonGroup
            class="float-right"
          >
            <CButton size="sm" color="primary" @click="onAddNew">
              <CIcon name="cil-plus" /> Add New
            </CButton>
            <CButton size="sm" color="success" @click="onRefresh">
              <CIcon name="cil-action-redo" /> Refresh
            </CButton>
          </CButtonGroup>
        </slot>
      </CCardHeader>
      <CCardBody>
        <list-pagination
          :pageData="pageData"
          :perPageSelectData="perPageSelect"
          :entity="entity"
          containerClass="header-pagination"
          @pageChange="changePage"
        />
        <div class="lists-table">
          <CDataTable
            :hover="true"
            :striped="true"
            :border="true"
            :small="true"
            :fixed="true"
            :items="lists"
            :fields="fields"
            :dark="false"
            :loading="fetching"
            :clickableRows="true"
            addTableClasses="header-fixed"
            :sorter="{ external: true }"
            :sorterValue="sorterValue"
            @row-clicked="rowClicked"
            @update:sorter-value="updateSort"
          >
            <template #status="{item}">
              <td class="text-center">
                <CBadge :color="item.is_active ? 'success' : 'danger'">
                  {{ item.is_active ? 'Active' : 'Inactive' }}
                </CBadge>
              </td>
            </template>
            <template #action="{item}">
              <td>
                <CButtonGroup size="sm">
                  <CButton color="primary" @click="() => onEditItem(item)">Edit</CButton>
                  <CButton color="danger" @click="() => onDeleteItem(item)">Delete</CButton>
                </CButtonGroup>
              </td>
            </template>
          </CDataTable>
        </div>
        <list-pagination
          :pageData="pageData"
          :perPageSelectData="perPageSelect"
          :entity="entity"
          containerClass="footer-pagination mt-2"
          @pageChange="changePage"
        />
      </CCardBody>
    </CCard>
    <Create
      v-if="newData.show"
      :key="newData.key"
    />
    <Update
      v-if="!!editData.data"
      :key="editData.key"
    />
    <Delete
      v-if="!!deleteData.data"
      :key="deleteData.key"
    />
    <Detail
      v-if="detailData.data"
      :key="detailData.key"
    />
  </div>
</template>

<script>
import startCase from 'lodash/startCase'
import { mapActions, mapState } from 'vuex'
import store from './store'

import Create from './components/Create'
import Update from './components/Update'
import Delete from './components/Delete'
import Detail from './components/Detail'

import { entity, tableIcon } from './entity'
import tableFields from './tableFields'

export default {
  name: `${startCase(entity)}Lists`,
  title: startCase(entity),
  props: {
    showNew: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Create,
    Update,
    Delete,
    Detail
  },
  data () {
    return {
      entity,
      tableIcon,
      pageTitle: startCase(entity),
      fields: tableFields,
      perPageSelect: {
        label: `${startCase(entity)} Per Page:`,
        options: [10, 15, 25, 50, 100],
        external: false
      }
    }
  },
  computed: {
    ...mapState({
      lists: state => state[entity].lists,
      pageData: state => state[entity].pageData,
      sortData: state => state[entity].sortData,
      fetching: state => state[entity].isFetching,
      newData: state => state[entity].new,
      editData: state => state[entity].edit,
      deleteData: state => state[entity].delete,
      detailData: state => state[entity].detail
    }),
    sorterValue () {
      const { column, direction } = this.sortData
      return {
        column,
        asc: direction === 'asc'
      }
    }
  },
  methods: {
    ...mapActions({
      loadLists: `${entity}/loadLists`,
      changePage: `${entity}/changePage`,
      changeSort: `${entity}/changeSort`
    }),
    onAddNew () {
      this.$store.commit(`${entity}/ADD_NEW`, true)
    },
    onRefresh () {
      this.loadLists()
    },
    onEditItem (item) {
      this.$store.commit(`${entity}/EDIT_DATA`, item)
    },
    onDeleteItem (item) {
      this.$store.commit(`${entity}/DELETE_DATA`, item)
    },
    onPageChange (currentPage) {
      this.changePage({ currentPage })
    },
    onPaginationChange (perPage) {
      this.changePage({ perPage })
    },
    rowClicked (item, idx, key) {
      if (key !== 'action') {
        this.$store.commit(`${entity}/DETAIL_DATA`, item)
      }
    },
    updateSort ({ asc, column }) {
      this.changeSort({ column, direction: asc ? 'asc' : 'desc' })
    }
  },
  beforeMount () {
    if (!this.$store.state[entity]) {
      this.$store.registerModule(entity, store)
    }
  },
  mounted () {
    this.loadLists()
  }
}
</script>
