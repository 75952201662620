<template>
  <CForm autocomplete="off">
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.code"
          name="code"
          label="Code *"
          placeholder="Code"
          :isValid="!errors.code ? null : false"
          :invalidFeedback="errors.code"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="item.description"
          name="description"
          label="Description *"
          placeholder="Description"
          :isValid="!errors.description ? null : false"
          :invalidFeedback="errors.description"
          @change="onHandleChange"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CSelect
          :value="item.type"
          name="type"
          label="Type *"
          :options="$options.typeLists"
          :isValid="!errors.type ? null : false"
          :invalidFeedback="errors.type"
          @update:value="onInputChanged"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInputCheckbox
          name="is_active"
          :checked="item.is_active"
          @update:checked="onInputChanged"
          label="Is Active"
        />
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import inputFields from '../inputFields'
import typeLists from '@/common/eventTypeLists'

export default {
  name: 'Form',
  typeLists,
  props: {
    data: {
      type: Object,
      defaultValue: {}
    },
    errors: {
      type: Object,
      defaultValue: {}
    }
  },
  data () {
    const defaultFields = Object.keys(inputFields).reduce((data, key) => {
      const { defaultValue } = inputFields[key]
      data[key] = defaultValue
      return data
    }, {})
    const item = Object.assign({}, defaultFields, this.data || {})
    return {
      item
    }
  },
  methods: {
    preventSubmit (event) {
      event.preventSubmit()
      event.preventDefault()
      event.stopPropagation()
    },
    onInputChanged (value, e) {
      const { target: { name } } = e
      this.item = { ...this.item, [name]: value }
      this.onHandleChange()
    },
    onHandleChange () {
      this.$emit('itemChange', this.item)
    }
  }
}
</script>
