<template>
  <CModal
    v-if="!!item"
    :show="true"
    :no-close-on-backdrop="true"
    :centered="true"
    size="lg"
    color="info"
  >
    <Form
      :data="item"
      :errors="errors"
      @itemChange="onItemChange"
    />
    <template #header>
      <h3 class="modal-title">Edit  {{ $options.entitySingle }} - {{ item.id }}</h3>
      <CButtonClose @click="onCancel" class="text-white"/>
    </template>
    <template #footer>
      <form-update-button
        :showLoading="isSubmitting"
        @update="onUpdate"
        @updateAndClose="onUpdateAndClose"
        @cancel="onCancel"
      />
    </template>
  </CModal>
</template>

<script>
import startCase from 'lodash/startCase'
import { mapActions, mapState } from 'vuex'
import Form from './Form'

import { entity, entitySingle } from '../entity'

export default {
  name: 'Update',
  components: {
    Form
  },
  entitySingle: startCase(entitySingle),
  data () {
    return {
      editItem: null
    }
  },
  computed: {
    ...mapState({
      item: state => state[entity].edit.data,
      isSubmitting: state => state[entity].edit.submitting,
      errors: state => state[entity].edit.errors
    })
  },
  methods: {
    ...mapActions({
      updateItem: `${entity}/updateItem`
    }),
    onItemChange (item) {
      this.editItem = item
    },
    async onUpdate () {
      const { editItem, item } = this
      if (editItem) {
        await this.updateItem({ id: item.id, data: editItem, successClose: false })
      }
    },
    async onUpdateAndClose () {
      const { editItem, item } = this
      if (editItem) {
        await this.updateItem({ id: item.id, data: editItem, successClose: true })
        return
      }
      this.onCancel()
    },
    onCancel () {
      this.$store.commit(`${entity}/EDIT_DATA`, false)
    }
  },
  mounted () {
    document.querySelector('body').classList.add('overflow-hidden')
  },
  destroyed () {
    document.querySelector('body').classList.remove('overflow-hidden')
  }
}
</script>
