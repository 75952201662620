import generateKey from '@/utils/generateKey'

export default {
  UPDATE_ENTITY (state, payload) {
    state.entity = payload.entity
    state.entity_single = payload.entity_single
  },
  ADD_NEW (state, payload) {
    state.new = { ...state.new, key: generateKey(), show: payload, errors: {} }
  },
  ADD_NEW_ERROR (state, payload) {
    state.new.errors = payload
  },
  ADD_NEW_SUBMITTING (state, payload) {
    state.new.submitting = payload
  },
  IS_FETCHING (state, payload) {
    state.isFetching = payload
  },
  FETCH_LISTS (state, payload) {
    state.lists = payload
  },
  DELETE_DATA (state, payload) {
    state.delete = { ...state.delete, key: generateKey(), data: payload }
  },
  DELETE_DATA_SUBMITTING (state, payload) {
    state.delete.submitting = payload
  },
  DETAIL_DATA (state, payload) {
    state.key = generateKey()
    state.detail.data = payload
  },
  EDIT_DATA (state, payload) {
    state.edit = { ...state.edit, key: generateKey(), data: payload, errors: {} }
  },
  EDIT_DATA_ERROR (state, payload) {
    state.edit.errors = payload
  },
  EDIT_DATA_SUBMITTING (state, payload) {
    state.edit.submitting = payload
  },
  UPDATE_PAGE_DATA (state, payload) {
    state.pageData = { ...state.pageData, ...payload }
  },
  UPDATE_SORT_DATA (state, payload) {
    state.sortData = { ...state.sortData, ...payload }
  },
  EDIT_FILTERS (state, payload) {
    state.filters = payload
  }
}
