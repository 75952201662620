<template>
  <CModal
    :show="true"
    :no-close-on-backdrop="true"
    :centered="true"
    size="lg"
    color="info"
  >
    <template #header>
      <h3 class="modal-title">{{ $options.entitySingle }} Detail - {{ item.id }}</h3>
      <CButtonClose @click="hideForm" class="text-white"/>
    </template>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Code</label>
          <strong class="d-block border px-3 py-2">{{ item.code }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <div class="form-group">
          <label>Description</label>
          <strong class="d-block border px-3 py-2">{{ item.description }} </strong>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CBadge :color="item.is_active ? 'success' : 'danger'" size="sm">
          <CIcon :name="item.is_active ? 'cil-check-alt' : 'cil-x'" />
        </CBadge>
        <span class="ml-2">Is Active</span>
      </CCol>
    </CRow>
    <template #footer>
      <CButton @click="hideForm" color="success">OK</CButton>
    </template>
  </CModal>
</template>

<script>
import startCase from 'lodash/startCase'
import { mapState } from 'vuex'
import { entity, entitySingle } from '../entity'

export default {
  name: 'Detail',
  entitySingle: startCase(entitySingle),
  computed: {
    ...mapState({
      item: state => state[entity].detail.data
    })
  },
  methods: {
    hideForm () {
      this.$store.commit(`${entity}/DETAIL_DATA`, null)
    }
  },
  mounted () {
    document.querySelector('body').classList.add('overflow-hidden')
  },
  destroyed () {
    document.querySelector('body').classList.remove('overflow-hidden')
  }
}
</script>
